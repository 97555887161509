import { HomeOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import PageWithSectionsContainer from 'components/PageWithSectionsContainer';
import PageWithSectionsContent from 'components/PageWithSectionsContent';
import PageWithSectionsHeader from 'components/PageWithSectionsHeader';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus/PartnerStatus';

import Alerts from './Alerts';
import OriginsSideMenu from './components/OriginsSideMenu';
import Customizations from './Customizations';
import OfflineFlow from './OfflineFlow';
import OriginBasicDetails from './OriginBasicDetails';
import RootOriginBasicDetails from './RootOriginBasicDetails';
import Rules from './Rules';
import Shutdown from './Shutdown';
import TextContents from './TextContents';
import Toplist from './Toplist';

interface Props extends WrappedComponentProps, RouteComponentProps<{ originId: string }> {}

@observer
class Origins extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  get breadcrumbs(): AppBreadcrumbItem[] {
    const {
      match: {
        params: { originId },
      },
    } = this.props;

    const breadcrumbs: AppBreadcrumbItem[] = [
      {
        text: <FormattedMessage id="main-navigation.patient-apps" />,
        icon: <HomeOutlined />,
        link: `/origins?expanded=${originId}`,
      },
      {
        text: originId,
      },
    ];

    return breadcrumbs;
  }

  async componentDidMount() {
    const { match } = this.props;

    if (match.params.originId) {
      this.fetchOrigin(match.params.originId);
      this.context.partnersStore.fetchPartnerUsersRoles();
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const {
      match: { params },
    } = this.props;
    const {
      match: { params: prevParams },
    } = prevProps;

    if (prevParams.originId !== params.originId) {
      await this.fetchOrigin(params.originId);
    }
  }

  componentWillUnmount() {
    this.context.customizationsStore.clearOriginCustomizations();
  }

  private fetchOrigin = async (originId: string) => {
    try {
      await this.context.originStore.fetchOrigin(originId);
    } catch (e: any) {
      this.handleNotFound('origins.errors.not-found', originId);
    }
  };

  private handleNotFound = (errorId: string, notFoundId: string) => {
    this.context.flashMessageService.translatedError(
      errorId,
      { autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT },
      { id: notFoundId }
    );
    this.props.history.push('/origins');
  };

  render() {
    const { match } = this.props;
    const { partnersStore, originStore, userPermissionsStore } = this.context;

    // Wait until origin data is loaded
    if (!originStore.currentOrigin) {
      return null;
    }

    const isRootOrigin = partnersStore.isRootOrigin(match.params.originId);
    const isSuperAdmin =
      userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin;

    return (
      <Fragment>
        <PartnerStatus />
        <PageWithSectionsHeader
          title={
            <span>
              <FormattedMessage id="main-navigation.patient-apps" />:{' '}
              {this.context.originStore.currentOrigin?.name}
            </span>
          }
          breadcrumbs={this.breadcrumbs}
        />
        <PageWithSectionsContainer>
          <OriginsSideMenu basePath={match.url} />
          <PageWithSectionsContent>
            <Switch>
              <Route
                path={`${match.path}/basic-details`}
                component={isRootOrigin ? RootOriginBasicDetails : OriginBasicDetails}
              />
              {isSuperAdmin && (
                <Route path={`${match.path}/customizations`} component={Customizations} />
              )}
              <Route path={`${match.path}/text-content`} component={TextContents} />
              <Route path={`${match.path}/alerts`} component={Alerts} />
              {isSuperAdmin && (
                <Route path={`${match.path}/offline-flow`} component={OfflineFlow} />
              )}
              <Route path={`${match.path}/shutdown-menu`} component={Shutdown} />
              {
                // TODO: enable when backend for https://platform24.atlassian.net/browse/AX-7711 is ready
                /*<Route
                path={`${match.path}/consents`}
                component={Consents}
              />*/
              }
              <Route path={`${match.path}/rules/:ruleName`} component={Rules} />
              <Route path={`${match.path}/toplist`} component={Toplist} />
              <Redirect from={match.path} to={`${match.path}/basic-details`} />
            </Switch>
          </PageWithSectionsContent>
        </PageWithSectionsContainer>
      </Fragment>
    );
  }
}

export default injectIntl(Origins);
