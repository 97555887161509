import { SaveOutlined } from '@ant-design/icons';
import { Card, Form, Typography, Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAtomValue } from 'jotai';
import { cloneDeep, isEqual, merge, omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { usePartnerImageData } from 'api/partnersApi.hooks';
import RichTextEditor from 'components/RichTextEditor';
import { LANGS } from 'constants/enums';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';
import { useNotificationApi } from 'state/notification';
import { partnersAtoms } from 'state/partners';

import { EditMetadataCard } from './EditMetadataCard';
import { useUpdateFullArticle } from '../../../api/articlesApi.hooks';
import { Article, ArticleDraft } from '../../../types/article';
import { CardTitle } from '../CardTitle';
import { EnduringProgressLoader } from '../EnduringProgressLoader';
import { LanguageIcon } from '../fields/LanguageIcon';
import { LanguageTabs } from '../LanguageTabs';
import { useSetSelectedLanguage, withLanguageTabState } from '../LanguageTabs/LanguageTabs.state';
import { UnsavedChangesNavigationBlocker } from '../UnsavedChangesNavigationBlocker';

const Styled = {
  Title: styled(Typography.Title)`
    font-weight: 500 !important;
    margin-bottom: 0 !important;
  `,
  CardTitle: styled(Typography.Title)`
    font-weight: normal !important;
  `,
  ActionBar: styled.div`
    display: flex;
    justify-content: center;
  `,
  PreviewButton: styled(Button)`
    margin-right: 0.5em;
  `,
  RedText: styled.span`
    color: var(--color-red);
  `,
  LanguageTabs: styled(LanguageTabs)`
    margin-top: -8px;
    position: relative;
  `,
};

interface MissingContent {
  lang: LANGS;
  field: 'text' | 'metadata.title';
}
type ValidationError = MissingContent | null;

interface EditArticleCardProps {
  currentArticle: Article;
  isLoading: boolean;
  disabled?: boolean;
  onEditCancel: () => void;
}
const EditArticleCardBase = ({
  currentArticle,
  isLoading,
  disabled,
  onEditCancel,
}: EditArticleCardProps) => {
  const [form] = useForm<ArticleDraft>();

  const availableLanguages = useAtomValue(partnersAtoms.availableLanguages);
  const defaultLanguage = useAtomValue(partnersAtoms.defaultLanguage);

  const partnerId = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID)!;
  const imageDataQuery = usePartnerImageData({
    partnerId,
    imageId: currentArticle.metadata.thumbnail,
  });
  useEffect(() => {
    if (!imageDataQuery.data) {
      return;
    }

    form.setFieldValue(['metadata', 'thumbnailBlobPath'], URL.createObjectURL(imageDataQuery.data));
  }, [form, imageDataQuery.data]);

  const checkHasChanges = useCallback(() => {
    const formData = form.getFieldsValue();

    const current = omit(currentArticle, ['id']);
    const updated = omit(merge(cloneDeep(current), formData), ['metadata.thumbnailBlobPath']);

    const hasChanges = !isEqual(updated, current);
    return hasChanges;
  }, [form, currentArticle]);

  const updateFullArticle = useUpdateFullArticle(partnerId);
  const [validationError, setValidationError] = useState<ValidationError>(null);
  const notificationApi = useNotificationApi();
  const setSelectedLanguage = useSetSelectedLanguage();
  const onSubmit = useCallback(() => {
    /**
     * Since the user might have not accessed all language tabs,
     * some language's data might be missing since antd's form will
     * have not initialized the field yet.
     * To circumvent this, we merge the current article with the
     * form values so that possible missing languages are taken from
     * the current article (initial data).
     */
    form.validateFields();
    const articleDraft = merge(cloneDeep(currentArticle), form.getFieldsValue());

    const missingContent = availableLanguages
      .map(lang => {
        if (!articleDraft.text[lang]?.trim()) {
          return { lang, field: 'text' } as const;
        }
        if (!articleDraft.metadata.title[lang]?.trim()) {
          return { lang, field: 'metadata.title' } as const;
        }

        return null;
      })
      .find(Boolean);
    if (missingContent) {
      setSelectedLanguage(missingContent.lang);
      setValidationError(missingContent);
      return;
    }

    updateFullArticle
      .mutateAsync({
        current: currentArticle,
        draft: articleDraft,
      })
      .then(({ wasUpdated }) => {
        onEditCancel();

        if (wasUpdated) {
          notificationApi.success({
            message: <FormattedMessage id="articles.update.success.message" />,
            description: <FormattedMessage id="articles.update.success.description" />,
            duration: 6,
          });
        }
      })
      .catch(() => {
        notificationApi.error({
          message: <FormattedMessage id="articles.update.error.message" />,
          description: <FormattedMessage id="articles.update.error.description" />,
          duration: 6,
        });
      });
  }, [
    form,
    currentArticle,
    availableLanguages,
    updateFullArticle,
    setSelectedLanguage,
    onEditCancel,
    notificationApi,
  ]);

  const isSaving = updateFullArticle.isLoading;
  const isDisabled = isLoading || disabled;

  return (
    <>
      <UnsavedChangesNavigationBlocker checkHasChanges={checkHasChanges} />

      <Form form={form} initialValues={currentArticle} layout="vertical" preserve>
        <Card
          title={
            <Styled.Title level={4} aria-level={2}>
              <FormattedMessage id="articles.edit.title" />
            </Styled.Title>
          }
          extra={
            <Styled.ActionBar>
              <Styled.PreviewButton onClick={onEditCancel} disabled={isDisabled}>
                <FormattedMessage id="general.cancel-editing" />
              </Styled.PreviewButton>

              <Button
                key="submit"
                type="primary"
                loading={isSaving}
                disabled={isDisabled}
                onClick={onSubmit}
                icon={<SaveOutlined />}
              >
                <FormattedMessage id="general.save" />
              </Button>
            </Styled.ActionBar>
          }
        >
          {isSaving && <EnduringProgressLoader label={<FormattedMessage id="articles.saving" />} />}

          <Styled.LanguageTabs defaultLanguage={defaultLanguage} languages={availableLanguages}>
            {({ selectedLanguage }) => (
              <>
                <Card size="small" style={{ marginBottom: 24 }}>
                  <EditMetadataCard
                    form={form}
                    isThumbnailImageLoading={
                      imageDataQuery.isLoading && imageDataQuery.fetchStatus !== 'idle'
                    }
                    selectedLanguage={selectedLanguage}
                  />
                </Card>

                <Card size="small">
                  <CardTitle
                    tooltip={<FormattedMessage id="articles.content.tooltip" />}
                    extra={<LanguageIcon language={selectedLanguage} />}
                  >
                    <Styled.RedText>*</Styled.RedText>{' '}
                    <FormattedMessage id="articles.content.title" />
                  </CardTitle>

                  <Form.Item name={['text', selectedLanguage]}>
                    <RichTextEditor
                      required
                      forceValidation={
                        validationError?.lang === selectedLanguage &&
                        validationError?.field === 'text'
                      }
                      isDisabled={isSaving || isLoading}
                      initialValue={form.getFieldValue(['text', selectedLanguage])}
                      onChange={content => {
                        form.setFieldValue(['text', selectedLanguage], content);
                      }}
                      contentType={RICH_TEXT_CONTENT_TYPES.MARKDOWN}
                      paragraphControls
                      imageControls
                      listStyleControls
                      inlineStyleControls
                      linkControls
                    />
                  </Form.Item>
                </Card>
              </>
            )}
          </Styled.LanguageTabs>
        </Card>
      </Form>
    </>
  );
};

export const EditArticleCard = withLanguageTabState(EditArticleCardBase);
