import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { useCallback, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  CareProviderInheritanceStatus,
  INHERITANCE_STATE,
  MODE,
  OnSubmitType,
  OpeningHoursRegular,
  OpeningHoursSpecial,
  OpeningHoursType,
} from './openingHours.types';
import { getClonedDataForUpdate, isSpecialHours } from './openingHours.utils';
import {
  fetchOpeningHours,
  fetchOpeningHoursInheritanceStatus,
  setOpeningHours,
} from '../../../api/openingHoursApi';
import RootStoreContext from '../../../context/RootStoreContext';

function getQueryKey(mode: MODE, id: string) {
  return mode === MODE.CARE_UNIT ? ['careUnitOpeningHours', id] : ['careProviderOpeningHours', id];
}

export const useInheritanceStatusQuery = (id: string) => {
  return useQuery<CareProviderInheritanceStatus>({
    queryKey: ['careProviderInheritanceStatus', id],
    queryFn: () => fetchOpeningHoursInheritanceStatus(id),
  });
};

export const useOpeningHoursQuery = (mode: MODE, id: string) => {
  return useQuery({
    queryKey: getQueryKey(mode, id),
    queryFn: () => fetchOpeningHours(mode, id),
    retry: 1,
  });
};

export const useCurrentCareUnitId = () => {
  const { careUnitStore } = useContext(RootStoreContext);
  return careUnitStore.careUnit?.id || '';
};

export const useCurrentCareProviderId = () => {
  const {
    params: { careProviderId },
  } = useRouteMatch<{ careProviderId: string }>();
  return careProviderId;
};

export const useCurrentId = (mode: MODE) => {
  const careUnitId = useCurrentCareUnitId();
  const careProviderId = useCurrentCareProviderId();
  return mode === MODE.CARE_UNIT ? careUnitId : careProviderId;
};

export const useOpeningHoursQueryForCurrentId = (mode: MODE) => {
  const careUnitId = useCurrentCareUnitId();
  const careProviderId = useCurrentCareProviderId();
  const id = mode === MODE.CARE_UNIT ? careUnitId : careProviderId;
  return useOpeningHoursQuery(mode, id);
};

export const useCurrentInheritanceState = <T extends OpeningHoursRegular | OpeningHoursSpecial>(
  mode: MODE
) => {
  const { data } = useOpeningHoursQueryForCurrentId(mode);
  const { values } = useFormikContext<T>();
  const isSpecial = isSpecialHours(values);
  const isInheritingFromQuery = isSpecial
    ? data?.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent
    : data?.detailedRegularHoursWrapper.regularHoursInheritedByParent;
  const isInheritingInForm = isSpecial
    ? values.specialHoursInheritedByParent
    : values.regularHoursInheritedByParent;

  if (isInheritingFromQuery && isInheritingInForm) {
    return INHERITANCE_STATE.INHERITED_FROM_CARE_PROVIDER;
  }

  if (isInheritingFromQuery && !isInheritingInForm) {
    return INHERITANCE_STATE.SPECIFIC_PENDING;
  }

  return INHERITANCE_STATE.SPECIFIC_SAVED;
};

export const useOpeningHoursOnSubmit = (mode: MODE, id: string) => {
  const { flashMessageService } = useContext(RootStoreContext);
  const queryClient = useQueryClient();
  const { data } = useOpeningHoursQuery(mode, id);

  return useCallback<OnSubmitType>(
    async values => {
      const isSpecial = isSpecialHours(values);
      const openingHoursNewValues: OpeningHoursType = {
        ...data!,
        ...(isSpecial
          ? { detailedSpecialHourPeriodsWrapper: values }
          : { detailedRegularHoursWrapper: values }),
      };
      try {
        await setOpeningHours(mode, id, getClonedDataForUpdate(openingHoursNewValues));
        flashMessageService.translatedSuccess(
          `opening-hours.${isSpecial ? 'special' : 'regular'}.saved`
        );
        queryClient.setQueryData(getQueryKey(mode, id), openingHoursNewValues);
      } catch (e: unknown) {
        if (e instanceof Error) {
          flashMessageService.error(e.message);
        }
        throw e;
      }
    },
    [data, id, flashMessageService, queryClient, mode]
  );
};
