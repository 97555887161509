import React from 'react';

import OpeningHours from './OpeningHours';
import { useCurrentCareProviderId } from './openingHours.hooks';
import { MODE } from './openingHours.types';

const OpeningHoursCareProvider = () => {
  const careProviderId = useCurrentCareProviderId();

  return <OpeningHours id={careProviderId} mode={MODE.CARE_PROVIDER} />;
};

export default OpeningHoursCareProvider;
