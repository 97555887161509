import { DeleteOutlined } from '@ant-design/icons';
import { Button, Flex, GetRef } from 'antd';
import { format, parse } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './OpeningHours.module.css';
import { TimePicker } from './TimePicker';
import { Toggle } from './Toggle';
import DateDisplay from '../../../../components/DateDisplay';
import DatePicker from '../../../../components/DatePicker';
import { useAntDLocale } from '../../../../config/antdConfigProvider';
import { useBoolean } from '../../../../hooks/useBoolean';
import {
  DATE_DISPLAY_FORMAT,
  DATE_INPUT_FORMAT,
  longDateToShort,
} from '../../../../utils/dateUtils';
import { OpeningHoursSpecial } from '../openingHours.types';

interface Props {
  name: string;
  disabled?: boolean;
  onDelete?: React.MouseEventHandler<HTMLElement> | null;
}

const SingleDayDatePicker = ({ name }: Pick<Props, 'name'>) => {
  const ref = useRef<GetRef<typeof DatePicker>>(null);
  const locale = useAntDLocale();
  const [
    { value: startDate },
    { touched },
    { setValue: setStartDate, setTouched: setTouchedStartDate },
  ] = useField(`${name}.startDate`);
  const [, , { setValue: setEndDate }] = useField(`${name}.endDate`);
  useEffect(() => {
    if (!touched && !startDate) {
      setTouchedStartDate(true);
      ref.current?.nativeElement.click();
    }
  }, [touched, setTouchedStartDate, startDate]);

  return (
    <DatePicker
      ref={ref}
      minDate={new Date()}
      locale={locale.DatePicker}
      value={startDate && parse(startDate, DATE_INPUT_FORMAT, new Date())}
      format={DATE_DISPLAY_FORMAT}
      onChange={async date => {
        const dateString = date && format(date, DATE_INPUT_FORMAT);
        await setStartDate(dateString);
        await setEndDate(dateString);
      }}
    />
  );
};

const DoubleDayDatePicker = ({ name }: Pick<Props, 'name'>) => {
  const locale = useAntDLocale();
  const [{ value: startDate }, , { setValue: setStartDate }] = useField(`${name}.startDate`);
  const [{ value: endDate }, , { setValue: setEndDate }] = useField(`${name}.endDate`);

  return (
    <DatePicker.RangePicker
      minDate={new Date()}
      locale={locale.DatePicker}
      value={[
        startDate && parse(startDate, DATE_INPUT_FORMAT, new Date()),
        endDate && parse(endDate, DATE_INPUT_FORMAT, new Date()),
      ]}
      format={DATE_DISPLAY_FORMAT}
      onChange={async dates => {
        const startDateString = dates && dates[0] && format(dates[0], DATE_INPUT_FORMAT);
        const endDateString = dates && dates[1] && format(dates[1], DATE_INPUT_FORMAT);
        await setStartDate(startDateString);
        await setEndDate(endDateString);
      }}
    />
  );
};

export const RowSpecialDay = ({ name, disabled, onDelete }: Props) => {
  const { values } = useFormikContext<OpeningHoursSpecial>();
  const [{ value: startDate }] = useField(`${name}.startDate`);
  const [{ value: endDate }, , { setValue: setEndDate, setTouched: setTouchedEndDate }] = useField(
    `${name}.endDate`
  );
  const { value: isSingleDateMode, setFalse: setDoubleDateMode } = useBoolean(
    startDate === endDate
  );
  const onSetDoubleDateMode = useCallback(async () => {
    await setEndDate(null);
    await setTouchedEndDate(false);
    setDoubleDateMode();
  }, [setDoubleDateMode, setEndDate, setTouchedEndDate]);

  return (
    <tr className={style.row}>
      <td className={style.day}>
        {disabled ? (
          <DateDisplay
            date={
              isSingleDateMode
                ? longDateToShort(startDate)
                : `${longDateToShort(startDate)} - ${longDateToShort(endDate)}`
            }
          />
        ) : isSingleDateMode ? (
          <Flex>
            <SingleDayDatePicker name={name} />
            <Button type="link" onClick={onSetDoubleDateMode}>
              <FormattedMessage id="opening-hours.add-end-date" />
            </Button>
          </Flex>
        ) : (
          <div className={style.doubleDates}>
            <DoubleDayDatePicker name={name} />
          </div>
        )}
      </td>
      <td className={style.openClosedCell}>
        <Toggle name={name} disabled={disabled} />
      </td>
      <td>{<TimePicker name={name} disabled={disabled} />}</td>
      {!values.specialHoursInheritedByParent && onDelete && (
        <td>
          <Button onClick={onDelete} icon={<DeleteOutlined />}>
            <FormattedMessage id="general.remove" />
          </Button>
        </td>
      )}
    </tr>
  );
};
