import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import style from './OpeningHours.module.css';
import { useCurrentInheritanceState } from '../openingHours.hooks';
import { INHERITANCE_STATE, MODE } from '../openingHours.types';

const STATE_TO_MESSAGE_MAP = {
  [MODE.CARE_UNIT]: {
    REGULAR: {
      [INHERITANCE_STATE.INHERITED_FROM_CARE_PROVIDER]: 'opening-hours.note.regular-inherited',
      [INHERITANCE_STATE.SPECIFIC_PENDING]: 'opening-hours.note.regular-inheritance-warning',
      [INHERITANCE_STATE.SPECIFIC_SAVED]: 'opening-hours.note.regular-own-warning',
    },
    SPECIAL: {
      [INHERITANCE_STATE.INHERITED_FROM_CARE_PROVIDER]: 'opening-hours.note.special-inherited',
      [INHERITANCE_STATE.SPECIFIC_PENDING]: 'opening-hours.note.special-inheritance-warning',
      [INHERITANCE_STATE.SPECIFIC_SAVED]: 'opening-hours.note.special-own-warning',
    },
  },
  [MODE.CARE_PROVIDER]: {
    REGULAR: 'opening-hours.note.regular-changes-warning',
    SPECIAL: 'opening-hours.note.special-changes-warning',
  },
};

function getMessageKey(mode: MODE, inheritanceState: INHERITANCE_STATE, isSpecialHours: boolean) {
  const specialOrRegular = isSpecialHours ? 'SPECIAL' : 'REGULAR';
  if (mode === MODE.CARE_UNIT) {
    return STATE_TO_MESSAGE_MAP[mode][specialOrRegular][inheritanceState];
  }

  return STATE_TO_MESSAGE_MAP[mode][specialOrRegular];
}

interface Props {
  mode: MODE;
  isSpecialHours: boolean;
}

export const InheritanceStateInfo = ({ mode, isSpecialHours }: Props) => {
  const intl = useIntl();
  const inheritanceState = useCurrentInheritanceState(mode);
  const messageKey = getMessageKey(mode, inheritanceState, isSpecialHours);

  return (
    <Alert
      className={style.inheritanceStateInfo}
      showIcon
      message={intl.formatMessage({ id: messageKey })}
      type={
        mode === MODE.CARE_UNIT && inheritanceState === INHERITANCE_STATE.SPECIFIC_PENDING
          ? 'warning'
          : 'info'
      }
    />
  );
};
