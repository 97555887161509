import { cloneDeep } from 'lodash';

import {
  OpeningHoursRegular,
  OpeningHoursRegularDay,
  OpeningHoursSpecial,
  OpeningHoursSpecificDay,
  OpeningHoursType,
  OpeningHoursTypeForUpdate,
} from './openingHours.types';

export function isSpecialHours(
  values: OpeningHoursRegular | OpeningHoursSpecial
): values is OpeningHoursSpecial {
  return 'detailedSpecialHourPeriods' in values;
}

export function clearDayTimes<Day extends OpeningHoursRegularDay | OpeningHoursSpecificDay>(
  day: Day
): Day {
  return {
    ...day,
    openTime: day.closedAllDay ? null : day.openTime,
    closeTime: day.closedAllDay ? null : day.closeTime,
  };
}

export function getClonedDataForUpdate(data: OpeningHoursType): OpeningHoursTypeForUpdate {
  const clonedData: OpeningHoursTypeForUpdate = cloneDeep(data);
  if (clonedData.detailedRegularHoursWrapper!.regularHoursInheritedByParent) {
    clonedData.detailedRegularHoursWrapper = null;
  } else {
    clonedData.detailedRegularHoursWrapper!.detailedRegularHours =
      clonedData.detailedRegularHoursWrapper!.detailedRegularHours.map(clearDayTimes);
  }

  if (clonedData.detailedSpecialHourPeriodsWrapper!.specialHoursInheritedByParent) {
    clonedData.detailedSpecialHourPeriodsWrapper = null;
  } else {
    clonedData.detailedSpecialHourPeriodsWrapper!.detailedSpecialHourPeriods =
      clonedData.detailedSpecialHourPeriodsWrapper!.detailedSpecialHourPeriods.map(clearDayTimes);
  }

  return clonedData;
}
