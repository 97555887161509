import { theme } from '@platform24/admin-ui-utils';
import { ConfigProvider } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import nbNO from 'antd/es/locale/nb_NO';
import nlNL from 'antd/es/locale/nl_NL';
import svSE from 'antd/es/locale/sv_SE';
import { set } from 'lodash';
import * as React from 'react';
import { useIntl } from 'react-intl';

import sv from '../translations/sv.json';

const translationsMap = {
  en: enUS,
  sv: svSE,
  no: nbNO,
  nl: nlNL,
  de: deDE,
};

set(translationsMap, 'sv.DatePicker.timePickerLocale.rangePlaceholder', [
  sv['opening-hours.time-picker.start-time'],
  sv['opening-hours.time-picker.end-time'],
]);

interface Props {
  children: React.ReactNode;
}

export const useAntDLocale = () => {
  const intl = useIntl();
  return translationsMap[intl.locale];
};

const AntdConfigProvider: React.FC<Props> = ({ children }: Props) => {
  const locale = useAntDLocale();
  const localProp = {
    ...locale,
    theme,
  };

  return <ConfigProvider {...localProp}>{children}</ConfigProvider>;
};

export default AntdConfigProvider;
