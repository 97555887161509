import React from 'react';

import { ErrorBox } from './components/ErrorBox';
import { FormRegular } from './components/FormRegular';
import { FormSpecial } from './components/FormSpecial';
import { useOpeningHoursOnSubmit, useOpeningHoursQuery } from './openingHours.hooks';
import { MODE, OpeningHoursRegular, OpeningHoursSpecial } from './openingHours.types';
import { Spinner } from '../../../components/Spinner';

interface Props {
  id: string;
  mode: MODE;
}

const OpeningHours = ({ id, mode }: Props) => {
  const { data, isLoading, isError, isSuccess, error } = useOpeningHoursQuery(mode, id);
  const onSubmit = useOpeningHoursOnSubmit(mode, id);

  if (isLoading && !data) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorBox error={error} />;
  }

  return isSuccess ? (
    <>
      <FormRegular
        initialValues={data.detailedRegularHoursWrapper}
        onSubmit={onSubmit<OpeningHoursRegular>}
        mode={mode}
      />
      <FormSpecial
        initialValues={data.detailedSpecialHourPeriodsWrapper}
        onSubmit={onSubmit<OpeningHoursSpecial>}
        mode={mode}
      />
    </>
  ) : null;
};

export default OpeningHours;
