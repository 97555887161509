import { Alert } from 'antd';
import { isAxiosError } from 'axios';
import React, { useEffect } from 'react';

import { logError } from '../../../Analytics/utils';

interface Props {
  error: unknown;
}

export const ErrorBox = ({ error }: Props) => {
  useEffect(() => {
    if (isAxiosError(error)) {
      logError(error, { type: 'error-box' });
    }
  }, [error]);

  if (isAxiosError(error)) {
    if (error.response?.status === 404) {
      return <Alert showIcon type="info" message="No opening hours set." />;
    }
    return (
      <Alert
        showIcon
        type="error"
        message="Error"
        description={
          <>
            <p>Something went wrong.</p>
            {error.response?.data.message && <p>{error.response?.data.message}</p>}
          </>
        }
      />
    );
  }
  return <Alert showIcon type="error" message="Error" description="Something went wrong" />;
};
