import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Input, Divider, Form, Typography, Space } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useState, useCallback } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DEV_LOGIN_CREDENTIALS } from 'constants/env';
import RootStoreContext from 'context/RootStoreContext';
import { DevCredentials } from 'types/types';

import styles from './SwedishDevLogin.module.css';

interface Props extends WrappedComponentProps, RouteComponentProps {}

const DevLogin = ({ intl, history }: Props) => {
  const { authStore, hsaidStore } = useContext(RootStoreContext);

  const [isLoading, handleLoadingStateChange] = useState(false);

  const handleDevLogin = useCallback(
    (hsaId: string) => {
      async function login(hsaId: string) {
        try {
          hsaidStore.setHSAID(hsaId);
          handleLoadingStateChange(true);
          await authStore.loginWithDevHsaId(hsaId);
          /* eslint-disable no-empty */
        } catch (error: any) {
        } finally {
          history.push('/login/select-partner');
        }
      }
      login(hsaId);
    },
    [authStore, handleLoadingStateChange, history]
  );

  const handleClick = useCallback(
    (id: string) => {
      handleDevLogin(id);
    },
    [handleDevLogin]
  );

  return (
    <Fragment>
      <Form layout="vertical">
        <Form.Item label={<FormattedMessage id="main-navigation.practitioners" />}>
          <div className={styles.devLogin}>
            {DEV_LOGIN_CREDENTIALS.map((credentials: DevCredentials) => (
              <Button
                key={credentials.id}
                onClick={() => {
                  handleClick(credentials.id);
                }}
                data-testid="dev-user-login-btn"
                disabled={isLoading}
              >
                {credentials.name}
              </Button>
            ))}
          </div>
        </Form.Item>
      </Form>
      <Divider>
        <FormattedMessage id="general.or" />
      </Divider>
      <Form onFinish={(values: Store) => handleDevLogin(values.hsaId)}>
        <Form.Item label={<FormattedMessage id="general.hsa-id" />} name="hsaId">
          <Input
            size="large"
            placeholder={intl.formatMessage({
              id: 'general.hsaid-pattern',
            })}
            data-testid="externalId-input"
            disabled={isLoading}
          />
        </Form.Item>
        <Observer>
          {() =>
            hsaidStore.HSAIDs?.length > 0 ? (
              <div className={styles.hsaidContainer}>
                <Typography.Title level={4}>Last used HSA-IDs</Typography.Title>
                <Space direction="vertical">
                  {hsaidStore.HSAIDs.map(id => (
                    <Button
                      data-testid={`${id}-button`}
                      type="text"
                      key={id}
                      className={styles.hsaidButtons}
                    >
                      <span className={styles.hsaidText} onClick={() => handleClick(id)}>
                        {id}
                      </span>
                      <span className={styles.hsaidCloseButton}>
                        <CloseCircleOutlined onClick={() => hsaidStore.removeHSAID(id)} />
                      </span>
                    </Button>
                  ))}
                </Space>
              </div>
            ) : null
          }
        </Observer>
        <Form.Item>
          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            htmlType="submit"
            size="large"
            block
            data-testid="externalId-login-btn"
          >
            <FormattedMessage id="login.login" />
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default injectIntl(withRouter(DevLogin));
