import { Formik, FormikConfig } from 'formik';
import { Form } from 'formik-antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { FormControlButtons } from './FormControlButtons';
import { InheritanceStateInfo } from './InheritanceStateInfo';
import { RowRegularDay } from './RowRegularDay';
import BoxContent from '../../../../components/BoxContent';
import { useBoolean } from '../../../../hooks/useBoolean';
import { MODE, OpeningHoursRegular } from '../openingHours.types';

interface Props {
  initialValues: OpeningHoursRegular;
  mode: MODE;
  onSubmit: FormikConfig<OpeningHoursRegular>['onSubmit'];
}

export const FormRegular = ({ initialValues, onSubmit, mode }: Props) => {
  const {
    value: isEditingEnabled,
    setTrue: setIsEditingTrue,
    setFalse: setIsEditingFalse,
  } = useBoolean(false);
  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      detailedRegularHours: Yup.array().when('regularHoursInheritedByParent', {
        is: false,
        then: Yup.array().of(
          Yup.object().shape({
            closedAllDay: Yup.boolean().required(),
            day: Yup.string().required(),
            openTime: Yup.string().nullable().when('closedAllDay', {
              is: false,
              then: Yup.string().required(),
            }),
            closeTime: Yup.string().nullable().when('closedAllDay', {
              is: false,
              then: Yup.string().required(),
            }),
          })
        ),
      }),
      regularHoursInheritedByParent: Yup.boolean().required(),
    });
  }, []);

  return (
    <Formik<OpeningHoursRegular>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      onReset={setIsEditingFalse}
    >
      {({ values }) => (
        <Form>
          <BoxContent
            title={<FormattedMessage id="opening-hours.regular.title" />}
            topRightContent={
              <FormControlButtons
                name="regularHoursInheritedByParent"
                isEditingEnabled={isEditingEnabled}
                onEnableEditing={setIsEditingTrue}
                onInheritFromParent={mode === MODE.CARE_UNIT ? setIsEditingFalse : undefined}
                mode={mode}
              />
            }
            topContent={<InheritanceStateInfo mode={mode} isSpecialHours={false} />}
          >
            <table>
              <tbody>
                {values.detailedRegularHours.map((day, index) => (
                  <RowRegularDay
                    key={index}
                    name={`detailedRegularHours[${index}]`}
                    disabled={!isEditingEnabled}
                  />
                ))}
              </tbody>
            </table>
          </BoxContent>
        </Form>
      )}
    </Formik>
  );
};
