import axios from 'axios';

import {
  CareProviderInheritanceStatus,
  MODE,
  OpeningHoursType,
  OpeningHoursTypeForUpdate,
} from '../modules/CareUnits/OpeningHours/openingHours.types';

function fixInheritanceStatusesFromCareProvider(data: OpeningHoursType) {
  // This is a workaround for the API not returning the correct inheritance status for care providers
  // It's a lot simpler to have those set to false rather than adding additional validation handling
  // to the forms.
  data.detailedRegularHoursWrapper.regularHoursInheritedByParent = false;
  data.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent = false;
}

function getPath(mode: MODE, id: string) {
  const pathPart = mode === MODE.CARE_UNIT ? 'care-units' : 'care-providers';
  return `/rest/care-providers-service/admin/${pathPart}/${id}/opening-hours/v1`;
}

export const fetchOpeningHours = async (mode: MODE, id: string) => {
  const response = await axios.get<OpeningHoursType>(getPath(mode, id));
  if (mode === MODE.CARE_PROVIDER) {
    fixInheritanceStatusesFromCareProvider(response.data);
  }
  return response.data;
};

export const setOpeningHours = async (mode: MODE, id: string, data: OpeningHoursTypeForUpdate) => {
  const response = await axios.put<OpeningHoursType>(getPath(mode, id), data);
  return response.data;
};

export const fetchOpeningHoursInheritanceStatus = async (id: string) => {
  const response = await axios.get<CareProviderInheritanceStatus>(
    `${getPath(MODE.CARE_PROVIDER, id)}/inheritance-status`
  );
  return response.data;
};
