import { Button, Typography } from 'antd';
import map from 'lodash/map';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { OriginSave } from 'api/originsApi';
import { PartnerUserRole } from 'api/partnersApi';
import UnitRoleForm from 'components/UnitRoleForm';
import UnitRolesTable from 'components/UnitRolesTable';
import { PARTNER_ROLES } from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import { UserRole } from 'types/types';
import { getValueOfFeatureFlag } from 'utils/appUtils';

import styles from './RootOriginBasicDetails.module.css';
import OriginsDetailsForm from '../components/OriginsDetailsForm';

interface Props extends WrappedComponentProps, RouteComponentProps<{ originId: string }> {}

@observer
class RootOriginBasicDetails extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable isAddRoleModalShown = false;

  originRolesOptions = map(PARTNER_ROLES, role => ({ label: role, value: role }));

  componentDidMount() {
    this.context.practitionersStore.fetchPractitioners();
  }

  handleSubmit = async (data: OriginSave) => {
    const {
      intl,
      match: {
        params: { originId },
      },
    } = this.props;
    const { originStore, flashMessageService, partnersStore } = this.context;

    if (!partnersStore.partnerRoles.length) {
      flashMessageService.error(intl.formatMessage({ id: 'origins.add-origin.no-roles-error' }));
      return;
    }

    try {
      await originStore.updateCurrentOrigin(originId, data);

      flashMessageService.success(intl.formatMessage({ id: 'origins.origin-updated-message' }));
      /* eslint-disable no-empty */
    } catch (e: any) {}
  };

  private handleDeleteRole = async (practitionerId: string) => {
    const { flashMessageService, partnersStore } = this.context;
    const { intl } = this.props;

    try {
      await partnersStore.deletePartnerRole(practitionerId);

      flashMessageService.success(intl.formatMessage({ id: 'origins.origin-updated-message' }));
      /* eslint-disable no-empty */
    } catch (error: any) {}
  };

  @action
  private handleAddEditRole = async (role: UserRole) => {
    const { flashMessageService, partnersStore } = this.context;
    const { intl } = this.props;

    this.isAddRoleModalShown = false;

    try {
      await partnersStore.savePartnerRole(role as PartnerUserRole);

      flashMessageService.success(intl.formatMessage({ id: 'origins.origin-updated-message' }));
      /* eslint-disable no-empty */
    } catch (error: any) {}
  };

  @action
  private handleAddRoleModalToggle = () => {
    this.isAddRoleModalShown = !this.isAddRoleModalShown;
  };

  render() {
    const {
      partnersStore,
      practitionersStore,
      userPermissionsStore,
      originStore,
      partnerStatusStore,
    } = this.context;
    const FF_ENABLE_NEW_ROLES = getValueOfFeatureFlag('FF_ENABLE_NEW_ROLES');
    const originRolesHeader =
      userPermissionsStore.canEditCurrentPartner ||
      this.context.userPermissionsStore.canEditOrigin(this.props.match.params.originId)
        ? 'origins.add-origin.add-roles-header'
        : 'origins.add-origin.origin-roles-header';

    if (!originStore.currentOrigin) {
      return null;
    }

    return (
      <Fragment>
        <div className={styles.container}>
          <Typography.Title level={2}>
            <FormattedMessage id="origins.basic-details" />
          </Typography.Title>
          <OriginsDetailsForm
            initialValues={originStore.currentOrigin}
            isSaving={originStore.isSaving() || originStore.isLoading()}
            onSubmit={this.handleSubmit}
            isDisabled={
              /**
               * NB: user should be able to edit origin if user is a super admin, has origin role write or admin or has new role config admin in the origin in question
               * TODO: remove !userPermissionsStore.canEditCurrentPartner after the migration to new roles
               */
              !userPermissionsStore.canEditCurrentPartner &&
              !this.context.userPermissionsStore.canEditOrigin(this.props.match.params.originId)
            }
          />
        </div>

        {(userPermissionsStore.isAuthorizedToEditPartner ||
          (FF_ENABLE_NEW_ROLES &&
            (userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin))) && (
          <div data-testid="origin-user-list">
            <Typography.Title level={2} className={styles.rolesHeader}>
              <FormattedMessage id={originRolesHeader} />
              <Button
                type="primary"
                shape="round"
                onClick={this.handleAddRoleModalToggle}
                disabled={originStore.isSaving() || partnerStatusStore.isMergeRequestPending}
              >
                <FormattedMessage id="add-roles-form.add-btn" />
              </Button>
            </Typography.Title>
            <UnitRolesTable
              practitionersWithRoles={partnersStore.partnerRoles}
              allPractitioners={practitionersStore.practitionersAsSelectOptions}
              roles={this.originRolesOptions}
              isSaving={partnersStore.isSaving()}
              isEditingAvailable={!partnerStatusStore.isMergeRequestPending}
              onDelete={this.handleDeleteRole}
              onEdit={this.handleAddEditRole}
            />
            <UnitRoleForm
              isAddMode
              roles={this.originRolesOptions}
              practitioners={practitionersStore.practitionersAsSelectOptions}
              isSaving={partnersStore.isSaving()}
              isVisible={this.isAddRoleModalShown}
              onCancel={this.handleAddRoleModalToggle}
              onSubmit={this.handleAddEditRole}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

export default injectIntl(RootOriginBasicDetails);
