import { Flex, Typography } from 'antd';
import { Field, FieldProps, useField } from 'formik';
import React from 'react';

import style from './OpeningHours.module.css';
import RangeTimePicker from '../../../../components/RangeTimePicker';
import {
  dateToHour,
  hourToDate,
  longHourToShort,
  TIME_FORMAT_LONG,
  TIME_FORMAT_SHORT,
} from '../../../../utils/dateUtils';

interface Props {
  name: string;
  disabled?: boolean;
}

export const TimePicker = ({ name, disabled }: Props) => {
  const [closedAllDay] = useField(`${name}.closedAllDay`);
  const [openTime, , openTimeHelpers] = useField(`${name}.openTime`);
  const [closeTime, , closeTimeHelpers] = useField(`${name}.closeTime`);

  return closedAllDay.value ? null : (
    <div className={style.timePicker}>
      {disabled ? (
        <Flex justify="space-around">
          <Typography.Text>{longHourToShort(openTime.value)}</Typography.Text>
          <Typography.Text>-</Typography.Text>
          <Typography.Text>{longHourToShort(closeTime.value)}</Typography.Text>
        </Flex>
      ) : (
        <Field name={`${name}.closeTime`}>
          {({ meta }: FieldProps) => {
            return (
              <RangeTimePicker
                value={[hourToDate(openTime.value), hourToDate(closeTime.value)]}
                format={TIME_FORMAT_SHORT}
                minuteStep={5}
                onChange={async dates => {
                  const [openTime, closeTime] = (dates as Date[] | null) || [null, null];
                  await openTimeHelpers.setValue(dateToHour(openTime, TIME_FORMAT_LONG));
                  await closeTimeHelpers.setValue(dateToHour(closeTime, TIME_FORMAT_LONG));
                }}
                status={meta.touched && meta.error ? 'error' : undefined}
              />
            );
          }}
        </Field>
      )}
    </div>
  );
};
