import { DeleteOutlined, PicLeftOutlined } from '@ant-design/icons';
import { routes } from '@platform24/admin-ui-utils';
import { Button, Card, Modal, Popconfirm, Popover, Skeleton, Typography } from 'antd';
import { useAtomValue } from 'jotai';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import withOnUnloadGuard from 'components/HOC/withOnUnloadGuard';
import PageHeader from 'components/PageHeader';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { useBoolean } from 'hooks/useBoolean';
import { content24Atoms } from 'state/content24';
import { useNotificationApi } from 'state/notification';
import { partnersAtoms } from 'state/partners';

import { EditArticleCard } from './EditArticleCard';
import { EnduringProgressLoader } from './EnduringProgressLoader';
import { PreviewArticleCard } from './PreviewArticleCard/PreviewArticleCard';
import { useDeleteArticle, useFullArticle } from '../../api/articlesApi.hooks';

const parseIdIntoName = (id: string) => id.toLocaleLowerCase().replace(/_/gi, ' ');

const Styled = {
  Container: styled.div`
    max-width: 820px;
  `,
  DeleteButton: styled(Button)`
    margin-bottom: 8px;
  `,
};

const ArticlePage = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const notificationApi = useNotificationApi();

  const partnerId = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID);
  const languages = useAtomValue(partnersAtoms.availableLanguages);
  const canEditSelfcareAdvice = useAtomValue(content24Atoms.canEditSelfcareAdvice);

  const fullArticleQuery = useFullArticle({
    partnerId,
    articleId: id,
    languages,
  });
  const isLoading = fullArticleQuery.isLoading && fullArticleQuery.fetchStatus !== 'idle';
  const article = fullArticleQuery.data;

  const [modal, confirmDeleteModalContextHolder] = Modal.useModal();
  const deleteArticle = useDeleteArticle();
  const handleDelete = useCallback(async () => {
    const confirmed = await modal.confirm({
      title: <FormattedMessage id="articles.delete.confirm.title" />,
      content: <FormattedMessage id="articles.delete.confirm.description" />,
      okText: <FormattedMessage id="general.delete" />,
      okButtonProps: {
        icon: <DeleteOutlined />,
      },
      cancelText: <FormattedMessage id="general.cancel" />,
      maskClosable: true,
    });
    if (!confirmed) {
      return;
    }

    try {
      await deleteArticle.mutateAsync(id);
    } catch (error) {
      notificationApi.error({
        message: <FormattedMessage id="articles.delete.error.message" />,
        description: <FormattedMessage id="articles.delete.error.description" />,
      });
      return;
    }

    notificationApi.success({
      message: <FormattedMessage id="articles.delete.success.message" />,
      description: <FormattedMessage id="articles.delete.success.description" />,
    });
    history.replace(routes.content24.articles.list.buildPath({}));
  }, [deleteArticle, history, id, modal, notificationApi]);

  useEffect(
    function redirectToListIfNotFound() {
      const isTryingToFetchArticle = fullArticleQuery.isLoading || fullArticleQuery.isFetching;

      if (!isTryingToFetchArticle && !fullArticleQuery.data) {
        history.replace(routes.content24.articles.list.buildPath({}));
      }
    },
    [history, fullArticleQuery.isLoading, fullArticleQuery.isFetching, fullArticleQuery.data]
  );

  const queryParameters = new URLSearchParams(window.location.search);
  const startEditing = queryParameters.get('startEditing');
  const isEditing = useBoolean(startEditing === 'true');

  useEffect(
    function clearStartEditingParam() {
      const queryParameters = new URLSearchParams(window.location.search);
      if (queryParameters.get('startEditing') === 'true') {
        queryParameters.delete('startEditing');
        history.replace({
          search: queryParameters.toString(),
        });
      }
    },
    [history]
  );

  const name = parseIdIntoName(id);

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <PicLeftOutlined />,
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="articles.title" />,
        link: routes.content24.articles.list.buildPath({}),
      },
      {
        text: name,
      },
    ],
    [name]
  );

  return (
    <Fragment>
      <Styled.Container>
        {deleteArticle.isLoading && <EnduringProgressLoader />}

        <PageHeader
          content={<Typography.Title level={2}>{id}</Typography.Title>}
          breadcrumbs={breadcrumbs}
          headerActions={
            <Popover
              open={canEditSelfcareAdvice ? false : undefined}
              content={
                <div style={{ maxWidth: 240 }}>
                  <FormattedMessage id="general.no-access" />
                </div>
              }
            >
              <Styled.DeleteButton
                icon={<DeleteOutlined style={{ marginRight: 4 }} />}
                loading={deleteArticle.isLoading}
                disabled={isLoading || !canEditSelfcareAdvice}
                onClick={handleDelete}
              >
                <>
                  <FormattedMessage id="articles.view.delete" />
                </>
              </Styled.DeleteButton>
            </Popover>
          }
        />

        {article ? (
          isEditing.value ? (
            <EditArticleCard
              currentArticle={article}
              isLoading={isLoading}
              disabled={deleteArticle.isLoading}
              onEditCancel={isEditing.setFalse}
            />
          ) : (
            <PreviewArticleCard
              article={article}
              isLoading={isLoading}
              disabled={deleteArticle.isLoading}
              onEdit={isEditing.setTrue}
            />
          )
        ) : (
          <Card title={<Skeleton.Input size="small" />}>
            <Skeleton />
          </Card>
        )}
      </Styled.Container>

      {confirmDeleteModalContextHolder}
    </Fragment>
  );
};

export default withOnUnloadGuard(ArticlePage);
