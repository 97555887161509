import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, Popconfirm } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PopoverTooltip } from 'components/PopoverTooltip';

import { Styled } from './EditMetadataCard.styles';
import { ImageUploadButton } from './ImageUploadButton';
import { ARTICLE_THUMBNAIL_SIZE } from '../../../constants';
import { ArticleDraft } from '../../../types/article';
import { CardTitle } from '../CardTitle';
import { LabelledLocalizedField } from '../fields/LabelledLocalizedField';
import { UnlocalizedFieldOutsideDefaultLangContainer } from '../fields/UnlocalizedFieldOutsideDefaultLangContainer';
import { useIsOutsideDefaultLanguage } from '../LanguageTabs/LanguageTabs.state';
import { ThumbnailImageFrame } from '../ThumbnailImageFrame';

/**
 * We are aiming for a 3x size of the thumbnail image
 * to avoid pixelation when the image is zoomed in.
 */
const TARGET_THUMBNAIL_SIZE = {
  width: ARTICLE_THUMBNAIL_SIZE.width * 3,
  height: ARTICLE_THUMBNAIL_SIZE.height * 3,
};

export const EditMetadataCard = ({
  isThumbnailImageLoading,
  selectedLanguage,
  form,
}: {
  isThumbnailImageLoading?: boolean;
  selectedLanguage: string;
  form: FormInstance<ArticleDraft>;
}) => {
  const intl = useIntl();

  const [isOutsideDefaultLanguage] = useIsOutsideDefaultLanguage();

  const thumbnailBlobPath = useWatch(formData => formData.metadata?.thumbnailBlobPath, form);

  const handleThumbnailImageUpload = useCallback(
    (blobPath: string | null) => {
      form.setFieldValue(['metadata', 'thumbnail'], null);
      form.setFieldValue(['metadata', 'thumbnailBlobPath'], blobPath);
    },
    [form]
  );

  return (
    <>
      <CardTitle tooltip={<FormattedMessage id="articles.metadata.tooltip" />}>
        <FormattedMessage id="articles.metadata.title" />
      </CardTitle>

      <Styled.ContentContainer>
        <Styled.ThumbnailContainer>
          <ThumbnailImageFrame src={thumbnailBlobPath} loading={isThumbnailImageLoading} />

          <Styled.ThumbnailImageControlSection>
            <Typography.Text>
              <FormattedMessage id="articles.metadata.thumbnail.title" />{' '}
              <PopoverTooltip>
                <FormattedMessage id="articles.metadata.thumbnail.tooltip" />
              </PopoverTooltip>
            </Typography.Text>

            {/* There needs to be an input related to these field, otherwise the
            form will not react to changes on their values  */}
            <Form.Item name={['metadata', 'thumbnail']} noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item name={['metadata', 'thumbnailBlobPath']} noStyle>
              <Input hidden />
            </Form.Item>

            <UnlocalizedFieldOutsideDefaultLangContainer active={isOutsideDefaultLanguage}>
              <ImageUploadButton
                label={
                  thumbnailBlobPath ? (
                    <FormattedMessage id="general.upload-new" />
                  ) : (
                    <FormattedMessage id="general.upload" />
                  )
                }
                targetImageSize={TARGET_THUMBNAIL_SIZE}
                disabled={isOutsideDefaultLanguage || !!isThumbnailImageLoading}
                onUpload={handleThumbnailImageUpload}
              />
            </UnlocalizedFieldOutsideDefaultLangContainer>

            {thumbnailBlobPath ? (
              <UnlocalizedFieldOutsideDefaultLangContainer active={isOutsideDefaultLanguage}>
                <Popconfirm
                  title={
                    <Styled.ThumbnailTitleContainer>
                      <FormattedMessage id="articles.thumbnail.delete.title" />
                    </Styled.ThumbnailTitleContainer>
                  }
                  cancelText={<FormattedMessage id="articles.thumbnail.delete.cancel" />}
                  okText={<FormattedMessage id="articles.thumbnail.delete.confirm" />}
                  onConfirm={() => {
                    form.setFieldValue(['metadata', 'thumbnailBlobPath'], null);
                    form.setFieldValue(['metadata', 'thumbnail'], null);
                  }}
                >
                  <Button disabled={isOutsideDefaultLanguage} icon={<DeleteOutlined />}>
                    <>
                      <FormattedMessage id="general.remove" />
                    </>
                  </Button>
                </Popconfirm>
              </UnlocalizedFieldOutsideDefaultLangContainer>
            ) : null}
          </Styled.ThumbnailImageControlSection>
        </Styled.ThumbnailContainer>

        <Styled.TextFieldsContainer>
          <LabelledLocalizedField
            language={selectedLanguage}
            label={intl.formatMessage({ id: 'articles.metadata.title-field.label' })}
            name={['metadata', 'title']}
            required
            tooltip={<FormattedMessage id="articles.metadata.title-field.tooltip" />}
          >
            <Input />
          </LabelledLocalizedField>

          {/* <Form.Item name={['metadata', 'tags']}>
            <Styled.TagsSelect />
          </Form.Item> */}

          <Styled.Description
            language={selectedLanguage}
            label={intl.formatMessage({ id: 'articles.metadata.description-field.label' })}
            name={['metadata', 'description']}
            tooltip={<FormattedMessage id="articles.metadata.description-field.tooltip" />}
          >
            <Input.TextArea />
          </Styled.Description>
        </Styled.TextFieldsContainer>
      </Styled.ContentContainer>
    </>
  );
};
