import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { endOfDay, parse } from 'date-fns';
import { FieldArray, Formik, FormikConfig } from 'formik';
import { Form } from 'formik-antd';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { FormControlButtons } from './FormControlButtons';
import { InheritanceStateInfo } from './InheritanceStateInfo';
import style from './OpeningHours.module.css';
import { RowSpecialDay } from './RowSpecialDay';
import BoxContent from '../../../../components/BoxContent';
import { useBoolean } from '../../../../hooks/useBoolean';
import { MODE, OpeningHoursSpecial } from '../openingHours.types';

interface Props {
  initialValues: OpeningHoursSpecial;
  mode: MODE;
  onSubmit: FormikConfig<OpeningHoursSpecial>['onSubmit'];
}

export const FormSpecial = ({ initialValues, onSubmit, mode }: Props) => {
  const {
    value: isEditingEnabled,
    setTrue: setIsEditingTrue,
    setFalse: setIsEditingFalse,
  } = useBoolean(false);
  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      detailedSpecialHourPeriods: Yup.array().when('specialHoursInheritedByParent', {
        is: false,
        then: Yup.array().of(
          Yup.object().shape({
            closedAllDay: Yup.boolean().required(),
            startDate: Yup.string().required(),
            endDate: Yup.string().required(),
            openTime: Yup.string().nullable().when('closedAllDay', {
              is: false,
              then: Yup.string().required(),
            }),
            closeTime: Yup.string().nullable().when('closedAllDay', {
              is: false,
              then: Yup.string().required(),
            }),
          })
        ),
      }),
      specialHoursInheritedByParent: Yup.boolean().required(),
    });
  }, []);

  return (
    <Formik<OpeningHoursSpecial>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      onReset={setIsEditingFalse}
    >
      {({ values, setFieldValue }) => {
        const now = new Date();
        const { futureDays, pastDays } = groupBy(values.detailedSpecialHourPeriods, day => {
          const isFuture =
            !day.endDate || endOfDay(parse(day.endDate, 'yyyy-MM-dd', new Date())) > now;
          return isFuture ? 'futureDays' : 'pastDays';
        });

        return (
          <Form>
            <BoxContent
              title={<FormattedMessage id="opening-hours.special.upcoming" />}
              topRightContent={
                <FormControlButtons
                  name="specialHoursInheritedByParent"
                  isEditingEnabled={isEditingEnabled}
                  onEnableEditing={setIsEditingTrue}
                  onInheritFromParent={mode === MODE.CARE_UNIT ? setIsEditingFalse : undefined}
                  mode={mode}
                />
              }
              topContent={<InheritanceStateInfo mode={mode} isSpecialHours />}
            >
              <FieldArray
                name="detailedSpecialHourPeriods"
                render={arrayHelpers => (
                  <>
                    {!futureDays && (
                      <p>
                        <Typography.Text type="secondary">
                          <FormattedMessage id="opening-hours.special.no-upcoming" />
                        </Typography.Text>
                      </p>
                    )}
                    {(isEditingEnabled || !futureDays) && (
                      <Button
                        className={style.addSpecialButton}
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={async () => {
                          setIsEditingTrue();
                          await setFieldValue('specialHoursInheritedByParent', false);
                          arrayHelpers.push({
                            startDate: null,
                            endDate: null,
                            openTime: null,
                            closeTime: null,
                            closedAllDay: true,
                          });
                        }}
                      >
                        <FormattedMessage id="opening-hours.special.add" />
                      </Button>
                    )}
                    {futureDays && (
                      <table>
                        <tbody>
                          {futureDays.map((day, index) => (
                            <RowSpecialDay
                              key={`${index}-${day.startDate}-${day.endDate}`}
                              name={`detailedSpecialHourPeriods[${values.detailedSpecialHourPeriods.indexOf(
                                day
                              )}]`}
                              disabled={!isEditingEnabled}
                              onDelete={
                                isEditingEnabled
                                  ? () =>
                                      arrayHelpers.remove(
                                        values.detailedSpecialHourPeriods.indexOf(day)
                                      )
                                  : null
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              />
            </BoxContent>
            <BoxContent title={<FormattedMessage id="opening-hours.special.previous" />}>
              {pastDays?.length ? (
                <table>
                  <tbody>
                    {pastDays?.map((day, index) => (
                      <RowSpecialDay
                        key={`${index}-${day.startDate}-${day.endDate}`}
                        name={`detailedSpecialHourPeriods[${values.detailedSpecialHourPeriods.indexOf(
                          day
                        )}]`}
                        disabled
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography.Text type="secondary">
                  <FormattedMessage id="opening-hours.special.no-previous" />
                </Typography.Text>
              )}
            </BoxContent>
          </Form>
        );
      }}
    </Formik>
  );
};
