import { QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip, Typography } from 'antd';
import React from 'react';

import style from './BoxContent.module.css';

interface Props {
  title: string | React.ReactNode;
  tooltip?: string;
  topRightContent?: React.ReactNode;
  topContent?: React.ReactNode;
  children?: React.ReactNode;
}

const BoxContent = ({ title, tooltip, topContent, topRightContent, children }: Props) => (
  <div className={style.container}>
    <div className={style.top}>
      <Flex justify="space-between" align="center">
        <div>
          <Typography.Text strong>{title}</Typography.Text>
          {tooltip && (
            <Tooltip title={tooltip}>
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </div>
        <div>{topRightContent}</div>
      </Flex>
      <div>{topContent}</div>
    </div>
    <div className={style.bottom}>{children}</div>
  </div>
);

export default BoxContent;
