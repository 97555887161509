import { BankOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import PageWithSectionsContainer from '../../components/PageWithSectionsContainer';
import PageWithSectionsContent from '../../components/PageWithSectionsContent';
import PageWithSectionsHeader from '../../components/PageWithSectionsHeader';
import SideMenu from '../../components/SideMenu/SideMenu';
import { Spinner } from '../../components/Spinner';
import { useRootStore } from '../../context/RootStoreContext';
import { OpeningHoursCareProvider } from '../CareUnits/OpeningHours';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

export const CareProvider = () => {
  const {
    params: { careProviderId },
  } = useRouteMatch<{ careProviderId: string }>();
  const { careUnitsStore } = useRootStore();
  const intl = useIntl();

  useEffect(() => {
    if (careUnitsStore.currentCareProvider?.id !== careProviderId) {
      careUnitsStore.fetchCareProvider(careProviderId);
    }
  }, [
    careUnitsStore.currentCareProvider,
    careUnitsStore.fetchCareProvider,
    careProviderId,
    careUnitsStore,
  ]);

  return (
    <Observer>
      {() => {
        if (careUnitsStore.isLoading()) {
          return <Spinner />;
        }

        return (
          <Fragment>
            <PartnerStatus />
            <PageWithSectionsHeader
              title={
                <>
                  <FormattedMessage id="care-units.care-providers" />:{' '}
                  {careUnitsStore.currentCareProvider?.name}
                </>
              }
              breadcrumbs={[
                {
                  icon: <BankOutlined />,
                  text: <FormattedMessage id="care-units.care-providers" />,
                  link: '/care-providers',
                },
                {
                  text: careProviderId,
                  link: `/care-providers/${careProviderId}`,
                },
              ]}
            />
            <PageWithSectionsContainer>
              <SideMenu
                links={[
                  {
                    url: `/care-providers/${careProviderId}/opening-hours`,
                    id: `/care-providers/${careProviderId}/opening-hours`,
                    content: intl.formatMessage({ id: 'care-units.opening-hours' }),
                  },
                ]}
              />
              <PageWithSectionsContent>
                <Switch>
                  <Route
                    path="/care-providers/:careProviderId/opening-hours"
                    component={OpeningHoursCareProvider}
                  />
                  <Redirect
                    from="/care-providers/:careProviderId"
                    to="/care-providers/:careProviderId/opening-hours"
                  />
                </Switch>
              </PageWithSectionsContent>
            </PageWithSectionsContainer>
          </Fragment>
        );
      }}
    </Observer>
  );
};
